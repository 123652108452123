import CoverageTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/coverage/CoverageTemplate';

import { COVERAGE, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Pimples/Coverage/CoverageTemplate',
  component: CoverageTemplate
};

const createStory = props => () => ({
  components: { CoverageTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><coverage-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  facialAcneCoverage: COVERAGE.SMALL,
  gender: GENDER.MALE
});
